import React from "react";

import { useTranslation } from "react-i18next";

import Fade from "react-reveal/Fade";

import main from '../../img/AccountTypes/main.png'

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[80px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between relative">
                <div className="pt-[60px]">
                    <p className="bebas text-[40px] xl:text-[64px] max-w-[477px] leading-[1.2]">{t('accounttypes.1')}</p>
                    <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] font-[300] mt-[20px] xl:mt-[40px]">{t('accounttypes.2')}</p>
                </div>
                <div className="pt-[34px]">
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default Main;