import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next";

import logo from "../img/logo.png";

function MobileMenu( { className }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();

    const { t } = useTranslation();

    return (
        <div className={className}> 
            <div className="relative z-[999] w-[30px] h-[25px] cursor-pointer flex flex-col justify-between xl::hidden" onClick={toggleMenu}>
                <div className={`w-full h-[1px] transition duration-300 transform ${isOpen ? 'rotate-45 translate-y-[9px] bg-[#000] w-[35px]' : 'bg-[#FFFFFFB2]'}`}></div>
                <div className={`w-full h-[1px] bg-[#FFFFFFB2] transition duration-300 transform ${isOpen ? 'hidden' : 'block '}`}></div>
                <div className={`w-full h-[1px]  transition duration-300 transform ${isOpen ? '-rotate-45 -translate-y-[15px] bg-[#000] w-[35px]' : 'bg-[#FFFFFFB2]'}`}></div>
            </div>
            <div className={`fixed top-0 right-0 bottom-0 w-full md:w-1/2 bg-white z-50 transform transition-all duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="flex flex-col items-center mt-[50px] gap-[15px]">
                    <img src={logo} alt="" className="w-[40px]"/>
                    <Link to="/trading"><p className={`text-[18px] roboto ${location.pathname === '/trading' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.1')}</p></Link>
                    <Link to="/bonus"><p className={`text-[18px] roboto ${location.pathname === '/bonus' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.3')}</p></Link>
                    <Link to="/account"><p className={`text-[18px] roboto ${location.pathname === '/account' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.2')}</p></Link>
                    <Link to="/support"><p className={`text-[18px] roboto ${location.pathname === '/support' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.4')}</p></Link>
                    <Link to="/about"><p className={`text-[18px] roboto ${location.pathname === '/about' ? 'font-bold' : 'font-medium'}`} onClick={toggleMenu}>{t('header.5')}</p></Link>
                    <div className="button cursor-pointer">
                        <p className="roboto text-[#FFF] font-[600] text-[16px]">{t('header.6')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;