import React from "react";

import { useTranslation } from "react-i18next";

import Fade from "react-reveal/Fade";

import main from '../../img/Home/main.png'

function Main () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[90px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="xl:h-[689px] rounded-[20px] xl:rounded-[40px] bg-[#262626] flex flex-col xl:flex-row relative">
                <div className="mx-[40px] pt-[40px] xl:pt-[100px]">
                    <p className="bebas uppercase text-[48px] xl:text-[96px] text-main leading-[1]">Dual Trade</p>
                    <p className="bebas text-white text-[36px] xl:text-[64px] leading-[1]">{t('main.1')}</p>
                    <p className="mt-[20px] xl:mt-[40px] max-w-[600px] text-[#FFFFFFB2] roboto font-[300] xl:text-[16px] text-[12px]">{t('main.2')}</p>
                    <a href="https://user.dual-trade.ltd">
                        <div className="button mt-[60px] cursor-pointer">
                            <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
                <div className="xl:absolute bottom-0 right-0">
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default Main;