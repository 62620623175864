import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css';

import acc1 from '../../img/AccountTypes/acc1.png'
import acc2 from '../../img/AccountTypes/acc2.png'
import acc3 from '../../img/AccountTypes/acc3.png'
import acc4 from '../../img/AccountTypes/acc4.png'
import acc5 from '../../img/AccountTypes/acc5.png'

import sw101 from '../../img/AccountTypes/sw101.png'
import sw102 from '../../img/AccountTypes/sw102.png'
import sw103 from '../../img/AccountTypes/sw103.png'
import sw104 from '../../img/AccountTypes/sw104.png'
import sw105 from '../../img/AccountTypes/sw105.png'

import sw201 from '../../img/AccountTypes/sw201.png'
import sw202 from '../../img/AccountTypes/sw202.png'
import sw203 from '../../img/AccountTypes/sw203.png'
import sw204 from '../../img/AccountTypes/sw204.png'
import sw205 from '../../img/AccountTypes/sw205.png'

import sw301 from '../../img/AccountTypes/sw301.png'
import sw302 from '../../img/AccountTypes/sw302.png'
import sw303 from '../../img/AccountTypes/sw303.png'
import sw304 from '../../img/AccountTypes/sw304.png'
import sw305 from '../../img/AccountTypes/sw305.png'

import sw401 from '../../img/AccountTypes/sw401.png'
import sw402 from '../../img/AccountTypes/sw402.png'
import sw403 from '../../img/AccountTypes/sw403.png'
import sw404 from '../../img/AccountTypes/sw404.png'
import sw405 from '../../img/AccountTypes/sw405.png'

import sw501 from '../../img/AccountTypes/sw501.png'
import sw502 from '../../img/AccountTypes/sw502.png'
import sw503 from '../../img/AccountTypes/sw503.png'
import sw504 from '../../img/AccountTypes/sw504.png'
import sw505 from '../../img/AccountTypes/sw505.png'

const data = [
    {
        title: 'accounttypes.3',
        text1: 'accounttypes.9',
        text2: 'accounttypes.10',
        img: acc1,
        swiper: [
            {
                title: 'accounttypes.11',
                text: 'accounttypes.12',
                svg:  sw101
            },
            {
                title: 'accounttypes.13',
                text: 'accounttypes.14',
                svg:  sw102
            },
            {
                title: 'accounttypes.15',
                text: 'accounttypes.16',
                svg:  sw103
            },
            {
                title: 'accounttypes.17',
                text: 'accounttypes.18',
                svg:  sw104
            },
            {
                title: 'accounttypes.19',
                text: 'accounttypes.20',
                svg:  sw105
            },
        ]
    },
    {
        title: 'accounttypes.4',
        text1: 'accounttypes.25',
        text2: 'accounttypes.26',
        img: acc2,
        swiper: [
            {
                title: 'accounttypes.27',
                text: 'accounttypes.28',
                svg:  sw201
            },
            {
                title: 'accounttypes.29',
                text: 'accounttypes.30',
                svg:  sw202
            },
            {
                title: 'accounttypes.31',
                text: 'accounttypes.32',
                svg:  sw203
            },
            {
                title: 'accounttypes.33',
                text: 'accounttypes.34',
                svg:  sw204
            },
            {
                title: 'accounttypes.35',
                text: 'accounttypes.36',
                svg:  sw205
            },
        ]
    },
    {
        title: 'accounttypes.5',
        text1: 'accounttypes.39',
        text2: 'accounttypes.40',
        img: acc3,
        swiper: [
            {
                title: 'accounttypes.41',
                text: 'accounttypes.42',
                svg:  sw301
            },
            {
                title: 'accounttypes.43',
                text: 'accounttypes.44',
                svg:  sw302
            },
            {
                title: 'accounttypes.45',
                text: 'accounttypes.46',
                svg:  sw303
            },
            {
                title: 'accounttypes.47',
                text: 'accounttypes.48',
                svg:  sw304
            },
            {
                title: 'accounttypes.49',
                text: 'accounttypes.50',
                svg:  sw305
            },
        ]
    },
    {
        title: 'accounttypes.6',
        text1: 'accounttypes.51',
        text2: 'accounttypes.52',
        img: acc4,
        swiper: [
            {
                title: 'accounttypes.53',
                text: 'accounttypes.54',
                svg:  sw401
            },
            {
                title: 'accounttypes.55',
                text: 'accounttypes.56',
                svg:  sw402
            },
            {
                title: 'accounttypes.57',
                text: 'accounttypes.58',
                svg:  sw403
            },
            {
                title: 'accounttypes.59',
                text: 'accounttypes.60',
                svg:  sw404
            },
            {
                title: 'accounttypes.61',
                text: 'accounttypes.62',
                svg:  sw405
            },
        ]
    },
    {
        title: 'accounttypes.7',
        text1: 'accounttypes.63',
        text2: 'accounttypes.64',
        img: acc5,
        swiper: [
            {
                title: 'accounttypes.65',
                text: 'accounttypes.66',
                svg:  sw501
            },
            {
                title: 'accounttypes.67',
                text: 'accounttypes.68',
                svg:  sw502
            },
            {
                title: 'accounttypes.69',
                text: 'accounttypes.70',
                svg:  sw503
            },
            {
                title: 'accounttypes.71',
                text: 'accounttypes.72',
                svg:  sw504
            },
            {
                title: 'accounttypes.73',
                text: 'accounttypes.74',
                svg:  sw505
            },
        ]
    },
]

function Switcher () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    const [swiper, setSwiper] = useState({});
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="flex flex-col gap-y-[10px]">
                    {data.map((ttt, index) => (
                        <div key={index} className={`w-[393px] h-[52px] rounded-[40px] ${active === index ? 'blockk' : 'bg-[#FEEFE6]'} flex items-center cursor-pointer`} onClick={() => setActive(index)}>
                            <div className="w-[42px] h-[42px] rounded-full bg-white flex items-center justify-center ml-[5px]">
                                <div className={`w-[16px] h-[16px] rounded-full ${active === index ? 'bg-[#F98D1D]' : 'bg-[#FEEFE6]'}`}></div>
                            </div>
                            <p className={`roboto text-[14px] xl:text-[16px] ${active === index ? 'text-white' : 'text-swiper-1'} font-[500] ml-[21px]`}>{t(ttt.title)}</p>
                        </div>
                    ))}
                </div>
                <div className="xl:w-[807px]">
                    <div className="xl:w-[807px] h-[350px] xl:h-[300px] bg-[#FEEFE6] rounded-[40px]">
                        <img src={data[active].img} alt="" className="mx-[20px] xl:mx-[80px] pt-[20px]"/>
                        <p className="mx-[20px] xl:mx-[80px] mt-[18px] manrope text-[20px] xl:text-[32px] font-[800] text-swiper-1">{t(data[active].title)}</p>
                        <p className="mx-[20px] xl:mx-[80px] mt-[12px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2]">{t(data[active].text1)}</p>
                    </div>
                    <Swiper
                        modules={[Navigation, Autoplay]}
                        slidesPerView={1}

                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        breakpoints={{
                            300: {
                            slidesPerView: 1,
                            },
                            768: {
                            slidesPerView: 2,
                            }
                        }}
                        className="xl:w-[807px] mt-[20px] xl:mt-[60px]"
                    >
                        {data[active].swiper.map((faqItem, faqIndex) => (
                            <SwiperSlide key={faqIndex}>
                                <div className="xl:w-[394px] h-[433px] rounded-[40px] border border-[#F98D1D]">
                                    <img src={faqItem.svg} alt="" className="mx-[20px] xl:mx-[40px] mt-[20px]"/>
                                    <p className="manrope text-swiper-1 font-[800] mx-[20px] xl:mx-[40px] mt-[20px] text-[16px] xl:text-[24px]">{t(faqItem.title)}</p>
                                    <p className="mt-[12px] mx-[20px] xl:mx-[40px] roboto text-[14px] xl:text-[16px] font-[300]">{t(faqItem.text)}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <p className="mt-[20px] xl:mt-[60px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2]">{t(data[active].text2)}</p>
                    <a href="https://user.dual-trade.ltd">
                        <div className="button mt-[60px] cursor-pointer mr-auto">
                            <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Switcher;