import React from "react";

import { useTranslation } from "react-i18next";

import bonus from '../../img/Home/bonus.png'

function WideBonus () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col">
            <div className="h-[442px] rounded-[40px] relative overflow-hidden">
                <img src={bonus} alt="" className="w-full h-full object-cover"/>
                <div className="absolute inset-0 bg-[#26262699]">
                    <p className="xl:text-[64px] text-[36px] bebas text-white mx-[20px] pt-[20px] xl:pt-[80px] xl:mx-[80px]">{t('main.33')}</p>
                    <p className="roboto text-[14px] xl:text-[16px] max-w-[517px] text-white mx-[20px] pt-[20px] xl:pt-[40px] xl:mx-[80px]">{t('main.34')}</p>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between pt-[40px] xl:pt-[80px]">
                <p className="max-w-[600px] font-[300] text-[#000000B2]">{t('main.35')}</p>
                <p className="max-w-[600px] font-[300] text-[#000000B2]">{t('main.36')}</p>
            </div>
            <a href="https://user.dual-trade.ltd">
                <div className="button_main mt-[20px] xl:mt-[40px] mx-auto">
                    <p className="text-swiper-1">{t('main.37')}</p>
                </div>
            </a>
        </div>
    );
}

export default WideBonus;