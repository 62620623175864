import React from "react";

import { useTranslation } from "react-i18next";

import man from '../../img/AccountTypes/man.png'

import Fade from 'react-reveal/Fade'

function DualTrade () {

    const { t } = useTranslation();

    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="h-[573px] rounded-[40px] bg-[#262626]">
                <p className="max-w-[663px] text-[16px] xl:text-[24px] text-white roboto pt-[20px] xl:pt-[100px] mx-[20px] xl:mx-[40px]">{t('accounttypes.75')}</p>
                <p className="max-w-[663px] text-[14px] font-[300] xl:text-[16px] text-white roboto pt-[20px] xl:pt-[40px] mx-[20px] xl:mx-[40px]">{t('accounttypes.76')}</p>
                <Fade right>
                    <img src={man} alt="" className="absolute right-0 bottom-0"/>
                </Fade>             
            </div>
        </div>
    );
}

export default DualTrade;