import React from "react";

import { useTranslation } from "react-i18next";

import check from '../../img/Home/check.svg'

import trading from '../../img/Home/trading_tools.png'

import Fade from 'react-reveal/Fade'

function TradingTools () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="bebas text-[#434343] xl:text-[64px] text-[36px]">{t('main.22')}</p>
                    <p className="mt-[12px] xl:max-w-[600px] font-[300] roboto text-[14px] xl:text-[16px] text-[#000000B2]">{t('main.23')}</p>
                    <div className="grid xl:grid-cols-2 gap-x-[90px] gap-y-[20px] mt-[20px] xl:mt-[45px]">
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.24')}</p>
                        </div>
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.25')}</p>
                        </div>
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.26')}</p>
                        </div>
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.27')}</p>
                        </div>
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.28')}</p>
                        </div>
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.29')}</p>
                        </div>
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.30')}</p>
                        </div>
                        <div className="flex gap-[16px]">
                            <img src={check} alt="" />
                            <p className="manrope text-[#000000B2] text-[16px] xl:text-[20px]">{t('main.31')}</p>
                        </div>
                    </div>
                    <p className="roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2] xl:max-w-[600px] mt-[20px] xl:mt-[80px]">{t('main.32')}</p>
                    <a href="https://user.dual-trade.ltd">
                        <div className="button_main mt-[20px] xl:mt-[40px]">
                            <p className="text-swiper-1">{t('main.37')}</p>
                        </div>
                    </a>
                </div>
                <div>
                    <Fade right>
                        <img src={trading} alt="" />
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default TradingTools;