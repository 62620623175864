import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import button from '../../img/TradingTools/button.png'

import f1 from '../../img/TradingTools/1.png'
import f2 from '../../img/TradingTools/2.png'
import f3 from '../../img/TradingTools/3.png'
import f4 from '../../img/TradingTools/4.png'
import f5 from '../../img/TradingTools/5.png'
import f6 from '../../img/TradingTools/6.png'
import f7 from '../../img/TradingTools/7.png'

const data = [
    {  
        button: 'tradingtools.21',
        title: 'tradingtools.29',
        img: f1,
        text1: 'tradingtools.37',
        faq: [
            {
                title: 'tradingtools.40',
                answer: 'tradingtools.41',
            },
            {
                title: 'tradingtools.42',
                answer: 'tradingtools.43',
            },
            {
                title: 'tradingtools.44',
                answer: 'tradingtools.45',
            },
            {
                title: 'tradingtools.46',
                answer: 'tradingtools.47',
            },
            {
                title: 'tradingtools.48',
                answer: 'tradingtools.49',
            },
            {
                title: 'tradingtools.50',
                answer: 'tradingtools.51',
            },
            {
                title: 'tradingtools.52',
                answer: 'tradingtools.53',
            },
        ],
        text2: 'tradingtools.38',
        text3: 'tradingtools.39',
    },
    {  
        button: 'tradingtools.22',
        title: 'tradingtools.30',
        img: f2,
        text1: 'tradingtools.54',
        faq: [
            {
                title: 'tradingtools.55',
                answer: 'tradingtools.56',
            },
            {
                title: 'tradingtools.57',
                answer: 'tradingtools.58',
            },
            {
                title: 'tradingtools.59',
                answer: 'tradingtools.60',
            },
            {
                title: 'tradingtools.61',
                answer: 'tradingtools.62',
            },
            {
                title: 'tradingtools.63',
                answer: 'tradingtools.64',
            },
            {
                title: 'tradingtools.65',
                answer: 'tradingtools.66',
            },
            {
                title: 'tradingtools.67',
                answer: 'tradingtools.68',
            },
            {
                title: 'tradingtools.69',
                answer: 'tradingtools.70',
            },
        ],
        text2: 'tradingtools.71',
        text3: 'tradingtools.72',  
    },
    {  
        button: 'tradingtools.23',
        title: 'tradingtools.31',
        img: f3,
        text1: 'tradingtools.73',
        faq: [
            {
                title: 'tradingtools.74',
                answer: 'tradingtools.75',
            },
            {
                title: 'tradingtools.76',
                answer: 'tradingtools.77',
            },
            {
                title: 'tradingtools.78',
                answer: 'tradingtools.79',
            },
            {
                title: 'tradingtools.80',
                answer: 'tradingtools.81',
            },
            {
                title: 'tradingtools.82',
                answer: 'tradingtools.83',
            },
            {
                title: 'tradingtools.84',
                answer: 'tradingtools.85',
            },
            {
                title: 'tradingtools.86',
                answer: 'tradingtools.87',
            },
            {
                title: 'tradingtools.88',
                answer: 'tradingtools.89',
            },
        ],
        text2: 'tradingtools.90', 
    },
    {  
        button: 'tradingtools.24',
        title: 'tradingtools.32',
        img: f4,
        text1: 'tradingtools.91',
        faq: [
            {
                title: 'tradingtools.92',
                answer: 'tradingtools.93',
            },
            {
                title: 'tradingtools.94',
                answer: 'tradingtools.95',
            },
            {
                title: 'tradingtools.96',
                answer: 'tradingtools.97',
            },
            {
                title: 'tradingtools.98',
                answer: 'tradingtools.99',
            },
            {
                title: 'tradingtools.100',
                answer: 'tradingtools.101',
            },
            {
                title: 'tradingtools.102',
                answer: 'tradingtools.103',
            },
            {
                title: 'tradingtools.104',
                answer: 'tradingtools.105',
            },
            {
                title: 'tradingtools.106',
                answer: 'tradingtools.107',
            },
        ],
        text2: 'tradingtools.108', 
        text3: 'tradingtools.109', 
    },
    {  
        button: 'tradingtools.25',
        title: 'tradingtools.33',
        img: f5,
        text1: 'tradingtools.110',
        faq: [
            {
                title: 'tradingtools.111',
                answer: 'tradingtools.112',
            },
            {
                title: 'tradingtools.113',
                answer: 'tradingtools.114',
            },
            {
                title: 'tradingtools.115',
                answer: 'tradingtools.116',
            },
            {
                title: 'tradingtools.117',
                answer: 'tradingtools.118',
            },
        ],
    },
    {  
        button: 'tradingtools.26',
        title: 'tradingtools.34',
        img: f6,
        text1: 'tradingtools.119',
        faq: [
            {
                title: 'tradingtools.120',
                answer: 'tradingtools.121',
            },
            {
                title: 'tradingtools.122',
                answer: 'tradingtools.123',
            },
            {
                title: 'tradingtools.124',
                answer: 'tradingtools.125',
            },
        ],
    },
    {  
        button: 'tradingtools.27',
        title: 'tradingtools.35',
        text1: 'tradingtools.126',
        faq: [
            {
                title: 'tradingtools.127',
                answer: 'tradingtools.128',
            },
            {
                title: 'tradingtools.129',
                answer: 'tradingtools.130',
            },
            {
                title: 'tradingtools.131',
                answer: 'tradingtools.132',
            },
            {
                title: 'tradingtools.133',
                answer: 'tradingtools.134',
            },
        ],
    },
    {  
        button: 'tradingtools.28',
        title: 'tradingtools.36',
        img: f7,
        text1: 'tradingtools.135',
        faq: [
            {
                title: 'tradingtools.136',
                answer: 'tradingtools.137',
            },
            {
                title: 'tradingtools.138',
                answer: 'tradingtools.139',
            },
            {
                title: 'tradingtools.140',
                answer: 'tradingtools.141',
            },
            {
                title: 'tradingtools.142',
                answer: 'tradingtools.143',
            },
        ],
        text2: 'tradingtools.144',
    },
]

function Switcher() {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="pt-[80px] xl:pt-[160px] relative max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden">
            <div className="flex flex-wrap xl:flex-row justify-around xl:justify-between gap-y-[10px] ">
                {data.map((dat, index) =>(
                    <div key={index} onClick={() => setActive(index)}>
                        <p className={`roboto text-[14px] xl:text-[16px] py-[6px] px-[16px] rounded-[40px] cursor-pointer ${active === index ? 'blockk text-white' : '!bg-[#FEEFE6] text-[#F98D1D]'}`}>{t(dat.button)}</p>
                    </div>
                ))}
            </div>
            <div className="flex flex-col xl:flex-row justify-between  mt-[40px] xl:mt-[60px]">
                <div>
                    <p className="max-w-[393px] bebas text-[40px] xl:text-[64px] text-[#434343] leading-[1.2]">{t(data[active].title)}</p>
                    <img src={data[active].img} alt="" className="hidden xl:block mt-[112px]"/>
                </div>
                <div>
                    <p className="max-w-[703px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2] pb-[20px] xl:pb-[30px]">{t(data[active].text1)}</p>
                    {data[active].faq && data[active].faq.length > 0 && (
                        <div className="max-w-[703px]">
                            {data[active].faq.map((faqItem, faqIndex) => (
                                <div key={faqIndex}>
                                    <div className={`flex mt-[10px] transition-all duration-300 bg-[#FEEFE6] ${openIndex === faqIndex ? 'h-auto rounded-[20px]' : 'h-[52px] items-center rounded-[40px]'}`}>
                                        <div className={`cursor-pointer w-[42px] h-[42px] bg-white rounded-full flex items-center justify-center ml-[5px] ${openIndex === faqIndex ? 'mt-[5px]' : 'mt-0'}`} onClick={() => toggleText(faqIndex)}>
                                            <img src={button} alt="" className={`${openIndex === faqIndex ? 'transition-transform rotate-45' : 'transition-transform'}`} />
                                        </div>
                                        <div>
                                            <p className={`ml-[26px] roboto text-[14px] xl:text-[16px] text-explore ${openIndex === faqIndex ? 'mt-[15px]' : 'mt-0'}`}>{t(faqItem.title)}</p>
                                            {openIndex === faqIndex && (
                                                <p className='ml-[26px] roboto font-light text-[14px] xl:text-[16px] mt-[10px] pb-[10px] max-w-[250px] xl:max-w-[600px]'>{t(faqItem.answer)}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <p className="max-w-[703px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px] xl:mt-[60px]">{t(data[active].text2)}</p>
                    <p className="max-w-[703px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px]">{t(data[active].text3)}</p>
                    <a href="https://user.dual-trade.ltd">
                        <div className="button mt-[20px] xl:mt-[40px] cursor-pointer">
                            <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="xl:flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[40px] hidden">
                {data.map((dat, index) =>(
                    <div key={index} onClick={() => setActive(index)}>
                        <p className={`roboto text-[14px] xl:text-[16px] py-[6px] px-[16px] rounded-[40px] cursor-pointer ${active === index ? 'blockk text-white' : '!bg-[#FEEFE6] text-[#F98D1D]'}`}>{t(dat.button)}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Switcher;