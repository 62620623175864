import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import HereWhat from "../components/Home/HereWhat";
import TradingTools from "../components/Home/TradingTools";
import WideBonus from "../components/Home/WideBonus";
import VariousAccounts from "../components/Home/VariousAccounts";
import Explore from "../components/Home/Explore";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <HereWhat/>
            <TradingTools/>
            <WideBonus/>
            <VariousAccounts/>
            <Explore/>
        </div>
    );
}

export default Home;