import React, { useEffect } from "react";

import Main from '../components/BonusSystem/Main'
import SecondMain from "../components/BonusSystem/SecondMain";
import Switcher from "../components/BonusSystem/Switcher";

function BonusSystem () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <SecondMain/>
            <Switcher/>
        </div>
    );
}

export default BonusSystem;