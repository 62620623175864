import React from "react";

import { useTranslation } from "react-i18next";

import w1 from '../../img/BonusSystem/welcome1.png'
import w2 from '../../img/BonusSystem/welcome2.png'
import w3 from '../../img/BonusSystem/welcome3.png'
import w4 from '../../img/BonusSystem/welcome4.png'
import w5 from '../../img/BonusSystem/welcome5.png'
import w6 from '../../img/BonusSystem/welcome6.png'

const data = [
    {
        title: 'bonussystem.3',  
        text: 'bonussystem.4',
        img: w1  
    },
    {
        title: 'bonussystem.5',  
        text: 'bonussystem.6',
        img: w2  
    },
    {
        title: 'bonussystem.7',  
        text: 'bonussystem.8',
        img: w3 
    },
    {
        title: 'bonussystem.9',  
        text: 'bonussystem.10',
        img: w4  
    },
    {
        title: 'bonussystem.11',  
        text: 'bonussystem.12',
        img: w5  
    },
    {
        title: 'bonussystem.13',  
        text: 'bonussystem.14',
        img: w6  
    },
]

function SecondMain () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden flex flex-col">
            <div className="grid xl:grid-cols-2">
                {data.map((ttt, index) => (
                    <div key={index} className="xl:w-[600px] flex flex-col">
                        <div className="xl:w-[600px] h-[1px] bg-[#F88A1D66]"></div>
                        <div className="flex items-center gap-[20px] xl:gap-[47px] mt-[30px]">
                            <img src={ttt.img} alt="" />
                            <p className="text-swiper-1 manrope text-[16px] xl:text-[24px] font-[700]">{t(ttt.title)}</p>
                        </div>
                        <p className="pb-[30px] ml-auto mr-[21px] max-w-[496px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px]">{t(ttt.text)}</p>
                    </div>
                ))}
            </div>
            <p className="mx-auto text-center mt-[20px] xl:mt-[50px] max-w-[600px] text-[#000000B2] roboto font-[300] text-[14px] xl:text-[16px]">{t('bonussystem.15')}</p>
            <a href="https://user.dual-trade.ltd">
                <div className="button mt-[30px] xl:mt-[30px] cursor-pointer mx-auto">
                    <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                </div>
            </a>
        </div>
    );
}

export default SecondMain;