import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import button from '../../img/TradingTools/button.png'

const data = [
    {
        title: 'bonussystem.16',
        faq: [
            {
                title: 'bonussystem.24',
                answer: 'bonussystem.25',
            },
            {
                title: 'bonussystem.26',
                answer: 'bonussystem.27',
            },
            {
                title: 'bonussystem.28',
                answer: 'bonussystem.29',
            },
            {
                title: 'bonussystem.30',
                answer: 'bonussystem.31',
            },
            {
                title: 'bonussystem.32',
                answer: 'bonussystem.33',
            },
            {
                title: 'bonussystem.34',
                answer: 'bonussystem.35',
            },
        ]
    },
    {
        title: 'bonussystem.17',
        faq: [
            {
                title: 'bonussystem.36',
                answer: 'bonussystem.37',
            },
            {
                title: 'bonussystem.38',
                answer: 'bonussystem.39',
            },
            {
                title: 'bonussystem.40',
                answer: 'bonussystem.41',
            },
            {
                title: 'bonussystem.42',
                answer: 'bonussystem.43',
            },
            {
                title: 'bonussystem.44',
                answer: 'bonussystem.45',
            },
            {
                title: 'bonussystem.46',
                answer: 'bonussystem.47',
            },
            {
                title: 'bonussystem.48',
                answer: 'bonussystem.49',
            },
        ]
    },
    {
        title: 'bonussystem.18',
        faq: [
            {
                title: 'bonussystem.50',
                answer: 'bonussystem.51',
            },
            {
                title: 'bonussystem.52',
                answer: 'bonussystem.53',
            },
            {
                title: 'bonussystem.54',
                answer: 'bonussystem.55',
            },
            {
                title: 'bonussystem.56',
                answer: 'bonussystem.57',
            },
            {
                title: 'bonussystem.58',
                answer: 'bonussystem.59',
            },
            {
                title: 'bonussystem.60',
                answer: 'bonussystem.61',
            },
            {
                title: 'bonussystem.62',
                answer: 'bonussystem.63',
            },
            {
                title: 'bonussystem.64',
                answer: 'bonussystem.65',
            },
        ]
    },
    {
        title: 'bonussystem.19',
        faq: [
            {
                title: 'bonussystem.66',
                answer: 'bonussystem.67',
            },
            {
                title: 'bonussystem.68',
                answer: 'bonussystem.69',
            },
            {
                title: 'bonussystem.70',
                answer: 'bonussystem.71',
            },
            {
                title: 'bonussystem.72',
                answer: 'bonussystem.73',
            },
            {
                title: 'bonussystem.74',
                answer: 'bonussystem.75',
            },
            {
                title: 'bonussystem.76',
                answer: 'bonussystem.77',
            },
            {
                title: 'bonussystem.78',
                answer: 'bonussystem.79',
            },
        ]
    },
    {
        title: 'bonussystem.20',
        faq: [
            {
                title: 'bonussystem.80',
                answer: 'bonussystem.81',
            },
            {
                title: 'bonussystem.82',
                answer: 'bonussystem.83',
            },
            {
                title: 'bonussystem.84',
                answer: 'bonussystem.85',
            },
            {
                title: 'bonussystem.86',
                answer: 'bonussystem.87',
            },
            {
                title: 'bonussystem.88',
                answer: 'bonussystem.89',
            },
            {
                title: 'bonussystem.90',
                answer: 'bonussystem.91',
            },
            {
                title: 'bonussystem.92',
                answer: 'bonussystem.93',
            },
            {
                title: 'bonussystem.94',
                answer: 'bonussystem.95',
            },
        ]
    },
    {
        title: 'bonussystem.21',
        faq: [
            {
                title: 'bonussystem.96',
                answer: 'bonussystem.97',
            },
            {
                title: 'bonussystem.98',
                answer: 'bonussystem.99',
            },
            {
                title: 'bonussystem.100',
                answer: 'bonussystem.101',
            },
            {
                title: 'bonussystem.102',
                answer: 'bonussystem.103',
            },
        ]
    },
]

function Switcher () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden flex flex-col">
            <div className="flex flex-col xl:flex-row justify-between">
                <div className="flex flex-col gap-y-[20px]">
                    {data.map((ttt, index) => (
                        <div key={index} className="cursor-pointer" onClick={() => setActive(index)}>
                            <p className={`bebas text-[30px] xl:text-[40px] leading-[1.2] ${active === index ? 'text-swiper-1' : 'text-[#434343]'}`}>
                                {active === index ? <>― </> : <></>}
                                {t(ttt.title)}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="max-w-[703px] mt-[20px] xl:mt-0">
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t('bonussystem.22')}</p>
                    {data[active].faq && data[active].faq.length > 0 && (
                        <div className="max-w-[703px] mt-[20px] xl:mt-[30px]">
                            {data[active].faq.map((faqItem, faqIndex) => (
                                <div key={faqIndex}>
                                    <div className={`flex mt-[10px] transition-all duration-300 bg-[#FEEFE6] ${openIndex === faqIndex ? 'h-auto rounded-[20px]' : 'h-[52px] items-center rounded-[40px]'}`}>
                                        <div className={`cursor-pointer w-[42px] h-[42px] bg-white rounded-full flex items-center justify-center ml-[5px] ${openIndex === faqIndex ? 'mt-[5px]' : 'mt-0'}`} onClick={() => toggleText(faqIndex)}>
                                            <img src={button} alt="" className={`${openIndex === faqIndex ? 'transition-transform rotate-45' : 'transition-transform'}`} />
                                        </div>
                                        <div>
                                            <p className={`ml-[26px] roboto text-[14px] xl:text-[16px] text-explore ${openIndex === faqIndex ? 'mt-[15px]' : 'mt-0'}`}>{t(faqItem.title)}</p>
                                            {openIndex === faqIndex && (
                                                <p className='ml-[26px] roboto font-light text-[14px] xl:text-[16px] mt-[10px] pb-[10px] max-w-[250px] xl:max-w-[600px]'>{t(faqItem.answer)}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300] mt-[20px] xl:mt-[40px]">{t('bonussystem.23')}</p>
                    <a href="https://user.dual-trade.ltd">
                        <div className="button mt-[60px] cursor-pointer">
                            <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Switcher;