import React from "react";

import { useTranslation } from "react-i18next";

function Explore () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between items-center relative">
                <div className="xl:max-w-[469px]">
                    <p className="text-[#434343] bebas xl:text-[64px] text-[36px] leading-[1.2]">{t('main.41')}</p>
                    <p className="textexplore xl:text-[64px] text-[36px]">{t('main.42')}</p>
                </div>
                <div className="flex mt-[30px] xl:mt-0">
                    <div className="flex flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <circle cx="20" cy="20" r="19.5" stroke="#F88A1D"/>
                            <circle cx="20" cy="20" r="6" fill="#F88A1D"/>
                        </svg>
                        <p className="text-explore manrope text-[16px] xl:text-[24px] font-[800] mt-[12px]">{t('main.43')}</p>
                        <p className="max-w-[149px] roboto text-[#000000B2] text-center font-[300] text-[14px] xl:text-[16px] mt-[12px]">{t('main.44')}</p>
                    </div>
                </div>
                <div className="hidden xl:block absolute h-[1px] w-[197px] bg-[#F88A1D66] right-[420px] top-[60px]"></div>
                <div className="flex mt-[30px] xl:mt-0">
                    <div className="flex flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className="opacity-[0.4]">
                            <circle cx="16" cy="16" r="15.5" stroke="#F88A1D"/>
                            <circle cx="16" cy="16" r="6" fill="#F88A1D"/>
                        </svg>
                        <p className="text-[#F88A1D66] manrope text-[16px] xl:text-[24px] font-[800] mt-[16px]">{t('main.45')}</p>
                        <p className="max-w-[225px] roboto text-[#000000B2] text-center font-[300] text-[14px] xl:text-[16px] mt-[12px]">{t('main.46')}</p>
                    </div>
                </div>
                <div className="hidden xl:block absolute h-[1px] w-[197px] bg-[#F88A1D66] right-[130px] top-[60px]"></div>
                <div className="flex mt-[30px] xl:mt-0">
                    <div className="flex flex-col items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className="opacity-[0.4]">
                            <circle cx="16" cy="16" r="15.5" stroke="#F88A1D"/>
                            <circle cx="16" cy="16" r="6" fill="#F88A1D"/>
                        </svg>
                        <p className="text-[#F88A1D66] manrope text-[16px] xl:text-[24px] font-[800] mt-[16px]">{t('main.47')}</p>
                        <p className="max-w-[179px] roboto text-[#000000B2] text-center font-[300] text-[14px] xl:text-[16px] mt-[12px]">{t('main.48')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Explore;