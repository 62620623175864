import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import sw1 from '../../img/Home/swiper01.png'
import sw2 from '../../img/Home/swiper02.png'
import sw3 from '../../img/Home/swiper03.png'
import sw4 from '../../img/Home/swiper04.png'
import sw5 from '../../img/Home/swiper05.png'
import sw6 from '../../img/Home/swiper06.png'
import sw7 from '../../img/Home/swiper07.png'
import sw8 from '../../img/Home/swiper08.png'

import prev from '../../img/back.svg'
import next from '../../img/next.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

const data = [
    {  
        title: 'main.5',
        text: 'main.6',
        img: sw1       
    },
    {  
        title: 'main.7',
        text: 'main.8',
        img: sw2       
    },
    {  
        title: 'main.9',
        text: 'main.10',
        img: sw3       
    },
    {  
        title: 'main.11',
        text: 'main.12',
        img: sw4       
    },
    {  
        title: 'main.13',
        text: 'main.14',
        img: sw5      
    },
    {  
        title: 'main.15',
        text: 'main.16',
        img: sw6       
    },
    {  
        title: 'main.17',
        text: 'main.18',
        img: sw7       
    },
    {  
        title: 'main.19',
        text: 'main.20',
        img: sw8       
    },
    
]

function HereWhat () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="bebas xl:text-[64px] text-[36px] text-[#434343] max-w-[413px] leading-[1.2]">{t('main.4')}</p>
                    <div className="flex gap-[20px] xl:mt-[163px] justify-between xl:justify-start mt-[40px]">
                        <img src={prev} alt="" onClick={() => swiper.slidePrev()}/>
                        <img src={next} alt="" onClick={() => swiper.slideNext()}/>
                    </div>
                </div>
                <div className="xl:w-[760px] mt-[20px] xl:mt-0">
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay]}
                        slidesPerView={1}
                        loop={true}
                        onInit={(e) => {
                            setSwiper(e);
                        }}
                        breakpoints={{
                            300: {
                            slidesPerView: 1,
                            },
                            768: {
                            slidesPerView: 1.5,
                            }
                        }}
                    >
                        {data.map((swiper, index) => (
                            <SwiperSlide key={index}>
                                <div className="bg-[#FEEFE6] rounded-[40px] md:w-[497px] h-[305px]">
                                    <div className="pt-[20px] mx-[20px] xl:mx-[40px]">
                                        <img src={swiper.img} alt=""/>
                                        <p className="text-swiper-1 manrope text-[16px] xl:text-[24px] font-[800] mt-[20px]">{t(swiper.title)}</p>
                                        <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300]">{t(swiper.text)}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <p className="xl:max-w-[760px] font-[300] roboto text-[#000000B2] text-[14px] xl:text-[16px] mt-[40px] xl:mt-[60px]">{t('main.21')}</p>
                </div>
            </div>
        </div>
    );
}

export default HereWhat;