import React from "react";

import { useTranslation } from "react-i18next";

import s1 from '../../img/SupportFaq/second1.png'
import s2 from '../../img/SupportFaq/second2.png'
import s3 from '../../img/SupportFaq/second3.png'
import s4 from '../../img/SupportFaq/second4.png'

import Fade from "react-reveal/Fade";

function MainSecond () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1220px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col">
                <div className="flex flex-col xl:flex-row gap-[20px] relative">
                    <div className="xl:w-[290px] h-[306px] rounded-[40px] relative overflow-hidden">
                        <img src={s1} alt="" className="w-full h-full object-cover"/>
                    </div>
                    <div className="xl:w-[600px] h-[320px] xl:h-[306px] bg-[#FEEFE6] rounded-[40px] flex flex-col">
                        <p className="mx-[20px] xl:mx-[40px] text-swiper-1 mt-[20px] xl:mt-[60px] manrope text-[16px] xl:text-[24px] font-[800]">{t('support.3')}</p>
                        <p className="mx-[20px] xl:mx-[40px] roboto text-[14px] xl:text-[16px] font-[300] mt-[12px]">{t('support.4')}</p>
                        <a href="https://user.dual-trade.ltd"><div className="flex items-center gap-[10px] mx-[20px] xl:mx-[40px] absolute bottom-[20px] cursor-pointer">
                            <p className="roboto text-[14px] xl:text-[16px] text-[#F98D1D] font-[500]">{t('support.5')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" fill="none">
                                <path d="M24 6L14 0.226497V11.7735L24 6ZM0 7H15V5H0V7Z" fill="#F98D1D"/>
                            </svg>
                        </div></a>
                    </div>        
                </div>
                <div className="flex flex-col xl:flex-row gap-[20px] ml-auto relative mt-[20px]">
                    <div className="xl:w-[290px] h-[306px] rounded-[40px] relative overflow-hidden">
                        <img src={s2} alt="" className="w-full h-full object-cover"/>
                    </div>
                    <div className="xl:w-[600px] h-[320px] xl:h-[306px] bg-[#FEEFE6] rounded-[40px] flex flex-col">
                        <p className="mx-[20px] xl:mx-[40px] text-swiper-1 mt-[20px] xl:mt-[60px] manrope text-[16px] xl:text-[24px] font-[800]">{t('support.6')}</p>
                        <p className="mx-[20px] xl:mx-[40px] roboto text-[14px] xl:text-[16px] font-[300] mt-[12px]">{t('support.7')}</p>
                        <a href="https://user.dual-trade.ltd"><div className="flex items-center gap-[10px] mx-[20px] xl:mx-[40px] absolute bottom-[20px] cursor-pointer">
                            <p className="roboto text-[14px] xl:text-[16px] text-[#F98D1D] font-[500]">{t('support.5')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" fill="none">
                                <path d="M24 6L14 0.226497V11.7735L24 6ZM0 7H15V5H0V7Z" fill="#F98D1D"/>
                            </svg>
                        </div></a>
                    </div>        
                </div>
                <div className="flex flex-col-reverse xl:flex-row gap-[20px] relative mt-[20px]">
                    <div className="xl:w-[600px] h-[320px] xl:h-[306px] bg-[#FEEFE6] rounded-[40px] flex flex-col">
                        <p className="mx-[20px] xl:mx-[40px] text-swiper-1 mt-[20px] xl:mt-[60px] manrope text-[16px] xl:text-[24px] font-[800]">{t('support.8')}</p>
                        <p className="mx-[20px] xl:mx-[40px] roboto text-[14px] xl:text-[16px] font-[300] mt-[12px]">{t('support.9')}</p>
                        <a href="https://user.dual-trade.ltd"><div className="flex items-center gap-[10px] mx-[20px] xl:mx-[40px] absolute bottom-[20px] cursor-pointer">
                            <p className="roboto text-[14px] xl:text-[16px] text-[#F98D1D] font-[500]">{t('support.5')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" fill="none">
                                <path d="M24 6L14 0.226497V11.7735L24 6ZM0 7H15V5H0V7Z" fill="#F98D1D"/>
                            </svg>
                        </div></a>
                    </div>    
                    <div className="xl:w-[290px] h-[306px] rounded-[40px] relative overflow-hidden">
                        <img src={s3} alt="" className="w-full h-full object-cover"/>
                    </div>    
                </div>
                <div className="flex flex-col-reverse xl:flex-row gap-[20px] relative mt-[20px] ml-auto">
                    <div className="xl:w-[600px] h-[320px] xl:h-[306px] bg-[#FEEFE6] rounded-[40px] flex flex-col">
                        <p className="mx-[20px] xl:mx-[40px] text-swiper-1 mt-[20px] xl:mt-[60px] manrope text-[16px] xl:text-[24px] font-[800]">{t('support.10')}</p>
                        <p className="mx-[20px] xl:mx-[40px] roboto text-[14px] xl:text-[16px] font-[300] mt-[12px]">{t('support.11')}</p>
                        <a href="https://user.dual-trade.ltd"><div className="flex items-center gap-[10px] mx-[20px] xl:mx-[40px] absolute bottom-[20px] cursor-pointer">
                            <p className="roboto text-[14px] xl:text-[16px] text-[#F98D1D] font-[500]">{t('support.5')}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12" fill="none">
                                <path d="M24 6L14 0.226497V11.7735L24 6ZM0 7H15V5H0V7Z" fill="#F98D1D"/>
                            </svg>
                        </div></a>
                    </div>    
                    <div className="xl:w-[290px] h-[306px] rounded-[40px] relative overflow-hidden">
                        <img src={s4} alt="" className="w-full h-full object-cover"/>
                    </div>    
                </div>
                <p className="max-w-[600px] roboto font-[300] text-[14px] xl:text-[16px] text-[#000000B2] mt-[20px] xl:mt-[60px]">{t('support.12')}</p>
                <a href="https://user.dual-trade.ltd"><div className="button mt-[40px] cursor-pointer mr-auto">
                    <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                </div></a>
            </div>
        </div>
    );
}

export default MainSecond;