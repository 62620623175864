import React, { useEffect } from "react";

import Main from '../components/SupportFaq/Main'
import MainSecond from "../components/SupportFaq/MainSecond";
import Centralized from "../components/SupportFaq/Centralized";
import Frequently from "../components/SupportFaq/Frequently";
import Troubleshooting from "../components/SupportFaq/Troubleshooting";
import Keeping from "../components/SupportFaq/Keeping";
import Explore from "../components/Home/Explore";

function SupportFaq () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <MainSecond/>
            <Centralized/>
            <Frequently/>
            <Troubleshooting/>
            <Keeping/>
            <Explore/>
        </div>
    );
}

export default SupportFaq;