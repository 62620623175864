import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import a1 from '../../img/Home/accounts1.png'
import a2 from '../../img/Home/accounts2.png'
import a3 from '../../img/Home/accounts3.png'
import a4 from '../../img/Home/accounts4.png'
import a5 from '../../img/Home/accounts5.png'

const accounts = [
    {
      name: 'Explorer Account',
      description:
        'Our intuitive and user-friendly interface makes it easy for you to navigate the platform, access your account information, and execute trades effortlessly. Whether you\'re using a desktop, laptop, or mobile device, our platform is optimized for a smooth trading experience across all devices.',
      icon: a1, // Add appropriate icon classes or elements
    },
    {
      name: 'Innovator Account',
      description:
        'The Innovator Account is tailor-made for those who strive to lead the market. With a suite of analytical tools and resources, you can stay ahead of trends and make informed decisions quickly and easily.',
      icon: a2, // Add appropriate icon classes or elements
    },
    {
      name: 'Visionary Account',
      description:
        'Open the Visionary Account and gain insights from our top-notch research team. This account is ideal for those who want to turn bold ideas into reality with the support of expert advice and innovative tools.',
      icon: a3, // Add appropriate icon classes or elements
    },
    {
      name: 'Maverick Account',
      description:
        'The Maverick Account is for those who redefine the norms and set their own rules. Benefit from customized solutions and exclusive access to advanced trading options.',
      icon: a4, // Add appropriate icon classes or elements
    },
    {
      name: 'Elite Account',
      description:
        'Step into the Elite Account for exclusive access to premium features and personalized services. Enjoy tailored investment strategies and superior support to maximize your trading potential.',
      icon: a5, // Add appropriate icon classes or elements
    },
  ];

function VariousAccounts () {

    const { t } = useTranslation();

    const [selectedAccount, setSelectedAccount] = useState(0);

    const handleButtonClick = (index) => {
        setSelectedAccount(index);
    };
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <p className="xl:text-[64px] text-[36px] bebas text-[#434343]">{t('main.38')}</p>
            <div className="flex flex-col justify-between">
                <div>
                    <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300] mt-[20px] max-w-[600px]">{t('main.39')}</p>       
                </div>
                 <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px]">
                        <div className="flex flex-col gap-[10px]">
                          {accounts.map((acc, index) => (
                            <div onClick={() => handleButtonClick(index)} className={`rounded-[40px] xl:w-[394px] h-[63px] cursor-pointer flex gap-[36px] items-center ${selectedAccount === index ? 'blockk' : 'border-[2px] border-[#F98E1D] bg-[#FEEFE6]'}`}>
                              <img src={acc.icon} alt="" className={`ml-[30px] w-[30px] h-[30px] xl:w-[40px] xl:h-[40px] ${selectedAccount === index ? 'filter invert brightness-0' : ''}`}/>
                              <p className={`manrope text-[16px] xl:text-[20px] font-[800] ${selectedAccount === index ? 'text-white' : 'text-swiper-1'}`}>{acc.name}</p>
                            </div>
                          ))}
                        </div>
                        <div className="blockk xl:w-[807px] h-[356px] rounded-[40px] mt-[12px] xl:mt-0"> 
                            <img src={accounts[selectedAccount].icon} alt="" className="filter invert brightness-0 mt-[20px] xl:mt-[40px] mx-[20px] xl:mx-[60px]"/>
                            <p className="text-white manrope text-[16px] xl:text-[24px] font-[800] mt-[26px] mx-[20px] xl:mx-[60px]">{accounts[selectedAccount].name}</p>
                            <p className="xl:ml-[60px] xl:mr-[100px] mx-[20px] xl:mx-0 text-[#FFFFFFB2] font-[300] roboto mt-[12px]">{accounts[selectedAccount].description}</p>
                        </div>
                    </div>
                    <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[120px]">
                      <p className="text-[#000000B2] roboto text-[14px] xl:text-[16px] font-[300] max-w-[600px]">{t('main.40')}</p>
                      <a href="https://user.dual-trade.ltd">
                        <div className="button_main mt-[20px] xl:mt-[40px] mx-auto cursor-pointer">
                            <p className="text-swiper-1">{t('main.37')}</p>
                        </div>
                      </a>
                    </div>
            </div>
            
        </div>
    );
}

export default VariousAccounts;