import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import acc1 from '../../img/AccountTypes/acc1.png'
import acc2 from '../../img/AccountTypes/acc2.png'
import acc3 from '../../img/AccountTypes/acc3.png'
import acc4 from '../../img/AccountTypes/acc4.png'
import acc5 from '../../img/AccountTypes/acc5.png'

function Info () {

    const { t } = useTranslation();

    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
           <div className="flex flex-col xl:flex-row justify-between">
                <div className="flex flex-col">
                    <div className="max-w-[510px]">
                        <div className="flex items-center">
                            <img src={acc1} alt="" className=""/>
                            <p className="text-swiper-1 ml-[20px] manrope text-[16px] xl:text-[20px] font-[800]">{t('accounttypes.3')}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[20px] gap-y-[5px]">
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.77')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.78')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.79')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.80')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.81')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.82')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.83')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.84')}</p>
                                <p className="roboto text-[#F88A1D] text-[12px] font-[500]">{t('accounttypes.85')}</p>
                            </div>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.86')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.87')}</p>
                                <p className="roboto text-[#F88A1D] text-[12px] font-[500]">{t('accounttypes.88')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:w-[553px] h-[1px] bg-[#F88A1D66] mt-[20px] xl:mt-[60px]"></div>       
                </div>
                <div className="bg-[#F88A1D66] w-[1px] h-[254px] hidden xl:block"></div>
                <div className="flex flex-col mt-[50px] xl:mt-0">
                    <div className="max-w-[510px]">
                        <div className="flex items-center">
                            <img src={acc2} alt="" className=""/>
                            <p className="text-swiper-1 ml-[20px] manrope text-[16px] xl:text-[20px] font-[800]">{t('accounttypes.4')}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[20px] gap-y-[5px]">
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.77')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.90')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.79')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.91')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.81')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.92')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.83')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.93')}</p>
                                <p className="roboto text-[#F88A1D] text-[12px] font-[500]">{t('accounttypes.94')}</p>
                            </div>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.86')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.95')}</p>
                                <p className="roboto text-[#F88A1D] text-[12px] font-[500]">{t('accounttypes.96')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:w-[553px] h-[1px] bg-[#F88A1D66] mt-[20px] xl:mt-[60px]"></div>       
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[50px]">
                <div className="flex flex-col">
                    <div className="max-w-[510px]">
                        <div className="flex items-center">
                            <img src={acc3} alt="" className=""/>
                            <p className="text-swiper-1 ml-[20px] manrope text-[16px] xl:text-[20px] font-[800]">{t('accounttypes.5')}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[20px] gap-y-[5px]">
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.77')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.97')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.79')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.98')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.81')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.99')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.83')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.100')}</p>
                                <p className="roboto text-[#F88A1D] text-[12px] font-[500]">{t('accounttypes.101')}</p>
                            </div>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.86')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.102')}</p>
                                <p className="roboto text-[#F88A1D] text-[12px] font-[500]">{t('accounttypes.103')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:w-[553px] h-[1px] bg-[#F88A1D66] mt-[20px] xl:mt-[45px]"></div>       
                </div>
                <div className="bg-[#F88A1D66] w-[1px] h-[254px] hidden xl:block"></div>
                <div className="flex flex-col">
                    <div className="max-w-[510px] mt-[50px] xl:mt-0">
                        <div className="flex items-center">
                            <img src={acc4} alt="" className=""/>
                            <p className="text-swiper-1 ml-[20px] manrope text-[16px] xl:text-[20px] font-[800]">{t('accounttypes.6')}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[20px] gap-y-[5px]">
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.77')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.104')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.79')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.105')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.81')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.106')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.83')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.107')}</p>
                                <p className="roboto text-[#F88A1D] text-[12px] font-[500]">{t('accounttypes.108')}</p>
                            </div>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.86')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.109')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:w-[553px] h-[1px] bg-[#F88A1D66] mt-[20px] xl:mt-[78px]"></div>       
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[50px]">
                <div className="flex flex-col">
                    <div className="max-w-[510px]">
                        <div className="flex items-center">
                            <img src={acc5} alt="" className=""/>
                            <p className="text-swiper-1 ml-[20px] manrope text-[16px] xl:text-[20px] font-[800]">{t('accounttypes.7')}</p>
                        </div>
                        <div className="grid grid-cols-2 mt-[20px] gap-y-[5px]">
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.77')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.110')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.79')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.111')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.81')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.112')}</p>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.83')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.113')}</p>
                            </div>
                            <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[300]">{t('accounttypes.86')}</p>
                            <div>
                                <p className="roboto text-[#000000B2] text-[14px] xl:text-[16px] font-[400]">{t('accounttypes.114')}</p>
                            </div>
                        </div>
                    </div>      
                </div>
                <div className="bg-[#F88A1D66] w-[1px] h-[254px] hidden xl:block"></div>
                <div className="flex flex-col">
                    <div className="xl:w-[510px]">
                        <p className="max-w-[497px] pt-[48px] roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300]">{t('accounttypes.89')}</p>  
                        <a href="https://user.dual-trade.ltd">
                            <div className="button mt-[40px] cursor-pointer">
                                <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                            </div>
                        </a>
                    </div>      
                </div>
            </div>
        </div>
    );
}

export default Info;