import React from "react";

import { useTranslation } from "react-i18next";

import Fade from "react-reveal/Fade";

import main from '../../img/AboutUs/a2.png'
import main2 from '../../img/AboutUs/a1.png'

function OurMission () {

    const { t } = useTranslation();
 
    return(
        <div className="pt-[80px] xl:pt-[80px] relative overflow-hidden max-w-[1280px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between relative">
                <div className="pt-[60px]">
                    <p className="bebas text-[40px] xl:text-[64px] max-w-[477px] leading-[1.2]">{t('support.77')}</p>
                    <p className="text-swiper-1 manrope text-[16px] xl:text-[24px] font-[800] mt-[20px] xl:mt-[40px]">{t('support.78')}</p>
                    <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] font-[300] mt-[20px]">{t('support.79')}</p>
                    <p className="text-swiper-1 manrope text-[16px] xl:text-[24px] font-[800] mt-[20px] xl:mt-[40px]">{t('support.80')}</p>
                    <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] font-[300] mt-[20px]">{t('support.81')}</p>
                </div>
                <div className="pt-[34px]">
                    <Fade right>
                        <img src={main} alt="" />
                    </Fade>
                </div>
            </div>
            <div className="pt-[80px] xl:pt-[160px] flex flex-col xl:flex-row justify-between relative">
                <div>
                    <Fade left>
                        <img src={main2} alt="" />
                    </Fade>
                </div>
                <div className="flex flex-col">
                    <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] font-[300] mt-[20px] xl:mt-[40px]">{t('support.74')}</p>
                    <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] font-[300] mt-[10px] xl:mt-[20px]">{t('support.75')}</p>
                    <p className="max-w-[600px] roboto text-[14px] xl:text-[16px] font-[300] mt-[10px] xl:mt-[20px]">{t('support.76')}</p>
                    <a href="https://user.dual-trade.ltd"><div className="button mt-[40px] cursor-pointer ml-auto">
                        <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                    </div></a>
                </div>
            </div>
        </div>
    );
}

export default OurMission;