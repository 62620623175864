import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import { Navigation, Autoplay, EffectCoverflow } from 'swiper/modules';

import { useTranslation } from "react-i18next";

import sw1 from '../../img/TradingTools/swiper1.png'
import sw2 from '../../img/TradingTools/swiper2.png'
import sw3 from '../../img/TradingTools/swiper3.png'
import sw4 from '../../img/TradingTools/swiper4.png'
import sw5 from '../../img/TradingTools/swiper5.png'
import sw6 from '../../img/TradingTools/swiper6.png'
import sw7 from '../../img/TradingTools/swiper7.png'
import sw8 from '../../img/TradingTools/swiper8.png'

import prev from '../../img/back.svg'
import next from '../../img/next.svg'

const data = [
    {  
        title: 'tradingtools.3',
        text: 'tradingtools.4',
        img: sw1       
    },
    {  
        title: 'tradingtools.5',
        text: 'tradingtools.6',
        img: sw2       
    },
    {  
        title: 'tradingtools.7',
        text: 'tradingtools.8',
        img: sw3       
    },
    {  
        title: 'tradingtools.9',
        text: 'tradingtools.10',
        img: sw4       
    },
    {  
        title: 'tradingtools.11',
        text: 'tradingtools.12',
        img: sw5      
    },
    {  
        title: 'tradingtools.13',
        text: 'tradingtools.14',
        img: sw6       
    },
    {  
        title: 'tradingtools.15',
        text: 'tradingtools.16',
        img: sw7       
    },
    {  
        title: 'tradingtools.17',
        text: 'tradingtools.18',
        img: sw8       
    },
    
]

function Swiperr() {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    return (
        <div className="pt-[80px] xl:pt-[160px] relative max-w-[1280px] mx-[20px] xl:mx-auto overflow-hidden">
            <Swiper
                modules={[Navigation, Autoplay, EffectCoverflow]}
                slidesPerView={1}
                effect="coverflow"
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false,
                }}
                loop={true}
                onInit={(e) => {
                    setSwiper(e);
                }}
                breakpoints={{
                    300: {
                    slidesPerView: 1,
                    },
                    768: {
                    slidesPerView: 3,
                    }
                }}
                className="!overflow-visible"
            >
                {data.map((swiper, index) => (
                    <SwiperSlide key={index}>
                        <div className="xl:w-[394px] h-[388px] rounded-[40px] blockk">
                            <div className="absolute w-[132px] h-[132px] rounded-full bg-white top-[-61.5px] left-1/2 -translate-x-1/2 xl:left-[130px] xl:translate-x-0 flex items-center justify-center">
                                <img src={swiper.img} alt="" />
                            </div>
                            <p className="text-white text-center pt-[100px] manrope text-[16px] xl:text-[24px] font-[800]">{t(swiper.title)}</p>
                            <p className="mx-[30px] mt-[20px] text-[#FFFFFFB2] roboto text-[14px] xl:text-[16px] font-[300]">{t(swiper.text)}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div>
                <div className="flex justify-between mt-[40px]">
                    <img src={prev} alt="" onClick={() => swiper.slidePrev()} className="cursor-pointer"/>
                    <img src={next} alt="" onClick={() => swiper.slideNext()} className="cursor-pointer"/>
                </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[80px]">
                <p className="max-w-[600px] roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('tradingtools.19')}</p>
                <p className="max-w-[600px] roboto text-[#000000B2] text-[14px] xl:text-[16px]">{t('tradingtools.20')}</p>
            </div>
            <div className="max-w-[1280px] flex">
                <a href="https://user.dual-trade.ltd">
                    <div className="button mt-[60px] cursor-pointer mx-auto">
                        <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Swiperr;