import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main'
import OurMission from "../components/AboutUs/OurMission";
import Client from "../components/AboutUs/Client";
import Regulatory from "../components/AboutUs/Regulatory";
import Technology from "../components/AboutUs/Technology";
import Partnerships from "../components/AboutUs/Partnerships";
import Explore from "../components/Home/Explore";

function AboutUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <OurMission/>
            <Client/>
            <Regulatory/>
            <Technology/>
            <Partnerships/>
            <Explore/>
        </div>
    );
}

export default AboutUs;