import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import button from '../../img/TradingTools/button.png'

const data = [
    {
        title: 'support.16',
        answer: 'support.17'
    },
    {
        title: 'support.18',
        answer: 'support.19'
    },
    {
        title: 'support.20',
        answer: 'support.21'
    },
    {
        title: 'support.22',
        answer: 'support.23'
    },
    {
        title: 'support.24',
        answer: 'support.25'
    },
    {
        title: 'support.26',
        answer: 'support.27'
    },
    {
        title: 'support.28',
        answer: 'support.29'
    },
    {
        title: 'support.30',
        answer: 'support.31'
    },
]

function Centralized () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
 
    return(
        <div className="pt-[80px] xl:pt-[160px] relative overflow-hidden max-w-[1220px] mx-[20px] xl:mx-auto">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <p className="max-w-[366px] bebas text-[40px] xl:text-[64px] text-[#262626] leading-[1.2]">{t('support.13')}</p>
                    <p className="max-w-[413px] roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] leading-[1.2] mt-[20px] xl:mt-[40px]">{t('support.14')}</p>
                </div>
                <div className="xl:w-[703px]">
                    {data.map((faqItem, faqIndex) => (
                        <div key={faqIndex}>
                            <div className={`flex mt-[10px] transition-all duration-300 bg-[#FEEFE6] ${openIndex === faqIndex ? 'h-auto rounded-[20px]' : 'h-[52px] items-center rounded-[40px]'}`}>
                                <div className={`cursor-pointer w-[42px] h-[42px] bg-white rounded-full flex items-center justify-center ml-[5px] ${openIndex === faqIndex ? 'mt-[5px]' : 'mt-0'}`} onClick={() => toggleText(faqIndex)}>
                                    <img src={button} alt="" className={`${openIndex === faqIndex ? 'transition-transform rotate-45' : 'transition-transform'}`} />
                                </div>
                                <div>
                                    <p className={`ml-[26px] roboto text-[14px] xl:text-[16px] text-explore ${openIndex === faqIndex ? 'mt-[15px]' : 'mt-0'}`}>{t(faqItem.title)}</p>
                                    {openIndex === faqIndex && (
                                        <p className='ml-[26px] roboto font-light text-[14px] xl:text-[16px] mt-[10px] pb-[10px] max-w-[250px] xl:max-w-[600px]'>{t(faqItem.answer)}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                    <p className="max-w-[703px] roboto text-[14px] xl:text-[16px] text-[#000000B2] font-[300] leading-[1.2] mt-[20px] xl:mt-[40px]">{t('support.15')}</p>
                    <a href="https://user.dual-trade.ltd">
                        <div className="button mt-[60px] cursor-pointer">
                            <p className="text-white roboto text-[16px] uppercase">{t('main.3')}</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Centralized;