import React, { useEffect } from "react";

import Main from '../components/AccountTypes/Main'
import Switcher from "../components/AccountTypes/Switcher";
import AlphaPlus from "../components/AccountTypes/AlphaPlus";
import Info from "../components/AccountTypes/Info";

function AccountTypes () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Switcher/>
            <AlphaPlus/>
            <Info/>
        </div>
    );
}

export default AccountTypes;