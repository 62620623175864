import React, { useEffect } from "react";

import Main from '../components/TradingTools/Main'
import Swiper from "../components/TradingTools/Swiper";
import Switcher from "../components/TradingTools/Switcher";
import Explore from "../components/Home/Explore";

function TradingTools () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Swiper/>
            <Switcher/>
            <Explore/>
        </div>
    );
}

export default TradingTools;